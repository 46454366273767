@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&display=swap");
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  --primary: #17c4e6;
  --font-light: #ffffff;
  --bg-dark: #070d16;
  --success: rgb(14, 203, 129);
  --error: red;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--primary);
}
